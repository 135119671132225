export const getSize = (file) => {
    let kb = file / 1024
    let mb = kb / 1024
    if (kb < 100) {
        return `${kb.toFixed(1)} КБ`
    } else {
        return `${mb.toFixed(1)} МБ`
    }
}

export const shortenString = (string, limit) => {
    let newString = string.split('.')
    let name = newString[0]
    let extension = newString[newString.length - 1]

    name = name.length > limit ?
        ( name.slice(0, limit - 2) + "..." ) :
        name;

    return name + (extension ? `.${extension}` : '');
}

export const sendSuccess = (url) => {
    window.location.href = url
}