import React from 'react'
import './style.scss'
import banner from './images/banner.png'
import bannerLogo from './images/banner-logo.png'

const Banner = () => {
    return(
        <div className={'banner'}>
            <div className={'banner__image-wrapper'}>
                <img src={banner} alt="banner"/>
            </div>
            <div className={'information'}>
                <p className={'information__title'}>Техническая поддержка и сопровождение для пользователей системы</p>
                <a href="https://omv-exam.ru/"><img src={bannerLogo} alt="banner logo" className={'information__logo'}/></a>
                <p className={'information__text'}>Здесь вы можете подать заявку на проведение <br/> одного или
                    нескольких экзаменов.<br/>
                    Можете связаться с нашими <br/>специалистами, через форму обратной связи <br/> или написать нам в
                    Телеграм,<br/>
                    а также скачать инструкции и технические требования</p>
            </div>
        </div>
    )
}

export default Banner