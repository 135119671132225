import React from 'react'
import Menu from "../../Components/Menu";
import RequestForExams from "../../Components/RequestForExams";

const RequestExams = () => {
    return(
        <div>
            <Menu/>
            <RequestForExams/>
        </div>
    )
}

export default RequestExams