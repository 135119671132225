import React from 'react'
import Menu from "../../Components/Menu";
import FormDistributive from "../../Components/FormDistributive";

const Distributive = () => {
    return(
        <>
         <Menu/>
         <FormDistributive/>
        </>
    )
}

export default Distributive