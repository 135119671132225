import React from 'react'
import Menu from "../../Components/Menu";
import Banner from "../../Components/Banner";
import Contacts from "../../Components/Contacts";
import Instructions from "../../Components/Instructions";
import Requirements from "../../Components/Requirements";

const Main = () => {
    return(
        <>
            <Menu/>
            <Banner/>
            <Contacts/>
            <Instructions/>
            <Requirements/>
        </>
    )
}

export default Main