import React from 'react'
import {HashRouter as Router, Switch, Route} from "react-router-dom";
import Main from "../Pages/Main";
import RequestExam from "../Pages/RequestExam";
import RequestExams from '../Pages/RequestExams'

import Success from "../Pages/Success";
import RequestSuccess from "../Components/RequestSuccess";
import FormSuccess from "../Components/FormSuccess";

import AskQuestion from "../Pages/AskQuestion";
import Distributive from "../Pages/Distributive";
import Footer from "../Components/Footer";

const App = () => {

    const contentWrapper = {minHeight:'calc(100vh - 56px)'}

    return(
        <>
            <div style={contentWrapper}>
                <Router>
                    <Switch>
                        <Route exact path={'/'} component={Main}/>
                        <Route path={'/request_exam'} component={RequestExam}/>
                        <Route path={'/request_exams'} component={RequestExams}/>
                        <Route path={'/success_request'}>
                            <Success component={RequestSuccess}/>
                        </Route>
                        <Route path={'/success_form'}>
                            <Success component={FormSuccess}/>
                        </Route>
                        <Route path={'/ask_question'} component={AskQuestion}/>
                        <Route path={'/distributive'} component={Distributive}/>
                    </Switch>
                </Router>
            </div>
            <Footer/>
        </>
    )
}

export default App