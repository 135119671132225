import React from 'react'
import {Link} from "react-router-dom";
import './style.scss'
import request from './images/request.png'
import requests from './images/requests.png'
import telegram from './images/telegram.png'
import form from './images/form.png'

const Contacts = () => {
    return (
        <div className={'contacts-wrapper'}>
            <div className={'contacts'}>
                <div className={'contact'}>
                    <div className={'contact__image-wrapper'}>
                        <img src={request} alt="request" className={'contact__image'}/>
                    </div>
                    <div className={'contact__information-wrapper contact__information-wrapper_request'}>
                        <Link to="/request_exam" className={'link'}>
                            <div className={'contact__information'}>
                                <p className={'contact__title'}>Подать заявку</p>
                                <p className={'contact__text'}>на проведение экзамена</p>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className={'contact'}>
                    <div className={'contact__image-wrapper'}>
                        <img src={requests} alt="request" className={'contact__image'}/>
                    </div>
                    <div className={'contact__information-wrapper contact__information-wrapper_requests'}>
                        <Link to={'/request_exams'} className={'link'}>
                            <div className={'contact__information'}>
                                <p className={'contact__title'}>Подать заявку</p>
                                <p className={'contact__text'}>на проведение нескольких экзаменов</p>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className={'contact'}>
                    <div className={'contact__image-wrapper'}>
                        <img src={telegram} alt="request" className={'contact__image'}/>
                    </div>
                    <div className={'contact__information-wrapper contact__information-wrapper_telegram'}>
                        <div className={'contact__information'}>
                            <p className={'contact__title'}>Написать нам</p>
                            <p className={'contact__text'}>сообщение в Телеграм</p>
                        </div>
                    </div>
                </div>
                <div className={'contact'}>
                    <div className={'contact__image-wrapper'}>
                        <img src={form} alt="request" className={'contact__image'}/>
                    </div>
                    <div className={'contact__information-wrapper contact__information-wrapper_form'}>
                        <Link to={'/ask_question'} className={'link'}>
                            <div className={'contact__information'}>
                                <p className={'contact__title'}>Задать вопрос</p>
                                <p className={'contact__text'}>через форму обратной связи</p>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contacts