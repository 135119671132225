import React from 'react'
import {Link, useLocation} from 'react-router-dom'
import * as Scroller from 'react-scroll'
import './style.scss'
import logo from './images/logo.png'
import userBlue from './images/userBlue.svg'
import apiWhite from './images/apiWhite.svg'
import errorStatus from './images/errorStatus.svg'
import okStatus from './images/okStatus.svg'

const Menu = () => {

    const location = useLocation()

    const scrollFunc = (position) => {
        let scroll = Scroller.animateScroll
        if (location.pathname !== '/') {
            window.location.href = '/#'
            scroll.scrollTo(position - 50)
        } else {
            scroll.scrollTo(position - 50)
        }
    }

    return (
        <div className={'menu-wrapper'}>
            <div className={'menu'}>
                    <Link to={'/'} onClick={() => scrollFunc(0)}>
                        <div className={'logo-wrapper'}>
                            <img src={logo} alt="logo"/>
                        </div>
                    </Link>
                <div className={'navigation'}>
                    <div className={'links-wrapper'}>
                        <ul className={'links'}>
                            <li className={'links__link'} onClick={() => scrollFunc(959)}>
                                Инструкции
                            </li>
                            <li className={'links__link'} onClick={() => scrollFunc(1318)}>
                                Технические требования
                            </li>
                        </ul>
                    </div>
                </div>
                {location.pathname === '/distributive'
                    ? ''
                    : <div className={'status-wrapper'}>
                        <div className={'status'}>
                            <p className={'status__text'}>Статус сервиса</p>
                            <div className={'status__image-wrapper status__image-wrapper_user'}>
                                <img src={userBlue} alt="user" className={'status__image'}/>
                                <img src={okStatus} alt="status" className={'status__api-status'}/>
                            </div>
                            <div className={'status__image-wrapper status__image-wrapper_api'}>
                                <img src={apiWhite} alt="api" className={'status__image'}/>
                                <img src={errorStatus} alt="status" className={'status__api-status'}/>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default Menu