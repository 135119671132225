import React from 'react'
import './style.scss'
import settings from './images/settings.png'
import teacher from './images/teacher.png'
import student from './images/student.png'

const Instructions = () => {
    return(
        <div className={'instructions'} id={'instructions'}>
            <h1 className={'instructions__title'}>Инструкции</h1>
            <div className={'instruction-wrapper'}>
                <div className={'instruction'}>
                    <img src={settings} alt="settings"/>
                    <div className={'instruction-text-wrapper'}>
                        <p className={'instruction__title'}>Инструкция</p>
                        <p className={'instruction__text'}>по настройке <br/>системы прокторинга <br/>OMV:Exam</p>
                    </div>
                </div>
                <div className={'instruction'}>
                    <img src={teacher} alt="teacher"/>
                    <div className={'instruction-text-wrapper'}>
                        <p className={'instruction__title'}>Инструкция</p>
                        <p className={'instruction__text'}>для преподавателя-проктора <br/>OMV:Exam</p>
                    </div>
                </div>
                <div className={'instruction'}>
                    <img src={student} alt="student"/>
                    <div className={'instruction-text-wrapper'}>
                        <p className={'instruction__title'}>Инструкция</p>
                        <p className={'instruction__text'}>для студента <br/>в системе <br/>OMV:Exam</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Instructions