import React, {useState, useRef, useEffect} from 'react'
import './style.scss'
import {useFormik} from 'formik'
import SimpleReactValidator from 'simple-react-validator'
import $ from 'jquery'
import {sendForm} from '../../assets/axios/sendForm'
import {sendSuccess} from '../../assets/js/functons'
import datetimepicker from 'react-xdsoft-datetimepicker'

const FormRequest = () => {

    const [notValid, setNotValid] = useState(false)
    const [startExamState, setStartExamState] = useState('')
    const [finishExamState, setFinishExamState] = useState('')

    const validator = useRef(new SimpleReactValidator({
        element: () => <span className={`validationRequest__not-valid`}>Заполните поле</span>
    }))

    const calendarValidator = useRef(new SimpleReactValidator({
        element: () => <span
            className={'validationRequest__not-valid validationRequest__not-valid_calendar'}>Выберите время и дату</span>
    }))

    const formik = useFormik({
        initialValues: {
            organizationName: '',
            agreementNumber: '',
            examName: '',
            examineesNumber: '',
            email: '',
            checkbox: true,
            startExam: '',
            finishExam: ''
        },
        onSubmit: () => {
            validator.current.showMessages()
            calendarValidator.current.showMessages()
            if (validator.current.allValid() && calendarValidator.current.allValid()) {
                setNotValid(false)
                const {
                    organizationName,
                    agreementNumber,
                    examName,
                    examineesNumber,
                    email,
                    startExam,
                    finishExam
                } = formik.values
                sendForm(
                    {organizationName, agreementNumber, examName, examineesNumber, email, startExam, finishExam},
                    sendSuccess('/#/success_request')
                )
            } else {
                setNotValid(true)
            }
        }
    })
    const {
        organizationName,
        agreementNumber,
        examName,
        examineesNumber,
        email,
        startExam,
        finishExam,
        checkbox
    } = formik.values

    useEffect(() => {
        const ids = ['agreementNumber', 'examName', 'examineesNumber', 'email']
        if (notValid) {
            ids.forEach(id => {
                if (!validator.current.fieldValid(id)) {
                    document.getElementById(id).classList.add('form__not-valid')
                } else {
                    document.getElementById(id).classList.remove('form__not-valid')
                }
            })
        }
    }, [notValid, formik.values])

    useEffect(() => {
        const ids = ['startExamId', 'finishExamId']
        if (notValid) {
            ids.forEach(id => {
                if (!calendarValidator.current.fieldValid(id)) {
                    document.getElementById(id).classList.add('form__not-valid')
                } else {
                    document.getElementById(id).classList.remove('form__not-valid')
                }
            })
        }
    }, [notValid, formik.values])

    useEffect(() => {
        formik.values.startExam = startExamState
        formik.values.finishExam = finishExamState
    }, [startExamState, finishExamState, formik.values])

    useEffect(() => {
        $('#startExamId').datetimepicker({
            format: 'd.m.Y H:i',
            inline: true,
            lang: 'ru',
            allowTimes: [
                '07:00', '08:00', '09:00', '10:00',
                '11:00', '12:00', '13:00', '15:00',
                '17:00', '18:00', '19:00', '20:00',
                '21:00', '22:00', '23:00'
            ],
            onChangeDateTime: (dp, $input) => {
                setStartExamState($input.val())
            }
        });

        $('#finishExamId').datetimepicker({
            format: 'd.m.Y H:i',
            inline: true,
            lang: 'ru',
            allowTimes: [
                '07:00', '08:00', '09:00', '10:00',
                '11:00', '12:00', '13:00', '15:00',
                '17:00', '18:00', '19:00', '20:00',
                '21:00', '22:00', '23:00'
            ],
            onChangeDateTime: (dp, $input) => {
                setFinishExamState($input.val())
            }
        });
    }, [])

    return (
        <div className={'request-wrapper'}>
            <h1 className={'request__title'}>Заявка на проведение экзамена</h1>
            <form className={'request-form'} onSubmit={formik.handleSubmit}>
                <div className={'request-form-wrapper'}>
                    <label className={'request-form__label'}>Название организации</label>
                    <input type="text" className={'request-form__input'} id={'organizationName'}
                           placeholder={'Введите название вашей органиации'}
                           value={organizationName} onChange={formik.handleChange}/>
                    <div className={'item-wrapper'}>
                        <label className={'request-form__label'}>Номер договора*</label>
                        <input type="text" className={'request-form__input'} id={'agreementNumber'}
                               placeholder={'Введите номер договора'}
                               value={agreementNumber} onChange={formik.handleChange}/>
                        {validator.current.message('agreementNumber', agreementNumber, 'required')}
                    </div>
                    <div className={'item-wrapper'}>
                        <label className={'request-form__label'}>Название экзамена*</label>
                        <input type="text" className={'request-form__input'} id={'examName'}
                               placeholder={'Введите название предмета'} value={examName}
                               onChange={formik.handleChange}/>
                        {validator.current.message('examName', examName, 'required')}
                    </div>
                    <div className={'item-wrapper'}>
                        <label className={'request-form__label'}>Количество экзаменуемых*</label>
                        <input type="number" className={'request-form__input'} id={'examineesNumber'}
                               placeholder={'Введите предполагаемое число экзаменуемых'}
                               value={examineesNumber} onChange={formik.handleChange}/>
                        {validator.current.message('examineesNumber', examineesNumber, 'required')}
                    </div>
                    <div className={'item-wrapper'}>
                        <label className={'request-form__label'}>Ваш e-mail*</label>
                        <input type="email" className={'request-form__input'} id={'email'}
                               placeholder={'Введие Ваш e-mail'} value={email}
                               onChange={formik.handleChange}/>
                        {validator.current.message('email', email, 'required|email')}
                    </div>
                    <p className={notValid ? 'request-form__info request-form__info_not-valid' : 'request-form__info'}>
                        * - поля обязательные для заполнения</p>
                    <div>
                        <input type="checkbox" className={'request-form__checkbox'} id={'checkbox'}
                               checked={checkbox}
                               onChange={formik.handleChange}/>
                        <span className={'request-form__label request-form__label_checkbox'}>
                            Даю согласие на обработку персональных данных</span>
                    </div>
                </div>
                <div className={'calendars-wrapper'}>
                    <div className={'calendars'}>
                        <p className={'request-form__label'}>Дата и время начала экзамена*</p>
                        <input id="startExamId" type="text"/>
                        {calendarValidator.current.message('startExam', startExam, 'required')}
                    </div>
                    <div className={'calendars'}>
                        <p className={'request-form__label'}>Дата и время окончания экзамена*</p>
                        <input id="finishExamId" type="text"/>
                        {calendarValidator.current.message('finishExam', finishExam, 'required')}
                    </div>
                    <button type={'submit'} className={'request-form__button'}>Отправить</button>
                </div>
            </form>
        </div>
    )
}

export default FormRequest