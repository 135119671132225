import React, {useState} from 'react'
import './style.scss'
import EnterPassword from "./EnterPassword";
import CorrectPassword from "./CorrectPassword";

const FormDistributive = () => {

    const [correct, setCorrect] = useState(false)

    return (
        <div className={'form-distributive-wrapper'}>
            <h1 className={'form-distributive__title'}>Дистрибутив для скачивания</h1>
            <p className={'form-distributive__text'}>Содержимое страницы защищено.
                {correct
                    ? ''
                    : <span> Для просмотра введите пароль.</span>
                }
            </p>
            {correct
                ? <CorrectPassword/>
                : <EnterPassword setCorrect={setCorrect}/>
            }
        </div>
    )
}

export default FormDistributive