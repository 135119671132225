import React from 'react'
import './style.scss'
import target from './images/target.png'
import pc from './images/pc.png'

const Requirements = () => {
    return(
        <div className={'requirements'} id={'requirements'}>
            <h1 className={'requirements__title'}>Технические требования</h1>
            <div className={'requirement-wrapper'}>
                <div className={'requirement'}>
                    <img src={target} alt="target"/>
                    <p className={'requirement__text'}>Для корректной работы плагина необходим Moodle версии не ниже 3.5</p>
                </div>
                <div className={'requirement'}>
                    <img src={pc} alt="pc"/>
                    <p className={'requirement__text'}>Для корректной работы необходимо HTTPS соединение на сайте Moodle</p>
                </div>
            </div>
        </div>
    )
}

export default Requirements