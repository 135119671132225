import React, {useState, useCallback, useRef, useEffect} from 'react'
import './style.scss'
import {v4 as uuidv4} from 'uuid'
import {useDropzone} from 'react-dropzone'
import {useFormik} from 'formik'
import SimpleReactValidator from 'simple-react-validator'
import {getSize, shortenString, sendSuccess} from '../../assets/js/functons'
import {sendForm} from '../../assets/axios/sendForm'
import success from './images/success.png'
import deleteFile from './images/delete.svg'
import dropActive from './images/drop_active.svg'
import drop from './images/drop.svg'

const AskForm = () => {

    const [uploadFiles, setUploadFiles] = useState([])
    const [totalSize, setTotalSize] = useState(0)
    const [notValid, setNotValid] = useState(false)
    const maxTotal = totalSize / 1024 / 1024 <= 10

    const validator = useRef(new SimpleReactValidator({
        element: () => <span className={`validation__not-valid`}>Заполните поле</span>
    }))

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            textArea: '',
            checkbox: true
        },
        onSubmit: () => {
            validator.current.showMessages()
            if (validator.current.allValid()) {
                setNotValid(false)
                if (maxTotal) {
                    const {name, email, textArea} = formik.values
                    sendForm({name, email, textArea, uploadFiles}, () => sendSuccess('/#/success_form'))
                }
            } else {
                setNotValid(true)
            }
        }
    })
    const {name, email, textArea, checkbox} = formik.values

    useEffect(() => {
        const ids = ['name', 'email']
        if (notValid) {
            ids.forEach(id => {
                if (!validator.current.fieldValid(id)) {
                    document.getElementById(id).classList.add('form__not-valid')
                } else {
                    document.getElementById(id).classList.remove('form__not-valid')
                }
            })
        }
    }, [notValid, formik.values])

    const onDrop = useCallback(acceptedFiles => {
        acceptedFiles.forEach(f => {
            let file = {id: uuidv4(), f}
            setUploadFiles(prev => [...prev, file])
            setTotalSize(prev => prev + file.f.size)
        })

    }, [])
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    const deleteFileFunc = (id, size) => {
        setUploadFiles(uploadFiles.filter(file => file.id !== id))
        setTotalSize(prev => prev - size)
    }

    const getFileLines = (length, count) => {
        if (length <= count) {
            let arrItems = []
            let i = length
            do {
                arrItems = [...arrItems, <div className={'upload-file'} key={i}/>]
                ++i
            } while (i <= count)
            return arrItems
        }
    }

    return (
        <div className={'ask-wrapper'}>
            <h1 className={'ask__title'}>Форма обратной связи</h1>
            <div>
                <form className={'ask-form'} onSubmit={formik.handleSubmit}>
                    <div className={'ask-form-inside-wrapper'}>
                        <div className={'item-wrapper'}>
                            <label className={'ask-form__label'}>Ваше имя*</label>
                            <input type="text" className={'ask-form__input'} placeholder={'Введите ваше имя'}
                                   value={name} id={'name'} onChange={formik.handleChange}/>
                            {validator.current.message('name', name, 'required')}
                        </div>
                        <div className={'item-wrapper'}>
                            <label className={'ask-form__label'}>Ваш e-mail*</label>
                            <input type="email" className={'ask-form__input'} placeholder={'Введите ваш e-mail*'}
                                   value={email} id={'email'} onChange={formik.handleChange}/>
                            {validator.current.message('email', email, 'required|email')}
                        </div>
                        <label className={'ask-form__label'}>Сообщение</label>
                        <textarea className={'ask-form__textarea'} placeholder={'Опишите проблему'} id={'textArea'}
                                  value={textArea} onChange={formik.handleChange}/>
                        <p className={notValid ? 'ask-form__text ask-form__text_not-valid' : 'ask-form__text'}>
                            * - поля обязательные для заполнения</p>
                        <div className={'ask-form__checkbox-wrapper'}>
                            <input type="checkbox" className={'ask-form__checkbox'} id={'checkbox'}
                                   checked={checkbox} onChange={formik.handleChange}/>
                            <label htmlFor={'checkbox'} className={'ask-form__checkbox-text'}>
                                Даю согласие на обработку персональных данных</label>
                            {validator.current.message('checkbox', checkbox, 'required|accepted')}
                        </div>
                    </div>
                    <div className={'ask-form__drop-zone-wrapper'}>
                        <label className={'ask-form__label'}>
                            Прикрепить скриншот
                            <span className={maxTotal ? '' : 'ask-form__label_not-valid'}> (не более 10 МБ)</span>
                        </label>
                        <div {...getRootProps()} className={'ask-form__drop-zone'}>
                            <input {...getInputProps()} />
                            {
                                isDragActive
                                    ? <div className={'drop-image drop-image_active'}>
                                        <img src={dropActive} alt="drop"/>
                                    </div>
                                    : <div className={'drop-image drop-image_static'}>
                                        <img src={drop} alt="drop"/>
                                        <p>Нажмите для загрузки или перетащите файлы</p>
                                    </div>
                            }
                        </div>
                        <div className={'upload-file-wrapper'}>
                            {uploadFiles.map(file => {
                                return (
                                    <div className={'upload-file'} key={file.id}>
                                        <div className={'upload-file__file'}>
                                            <p className={'upload-file__title'}>{shortenString(file.f.name, 10)}</p>
                                            <div className={'upload-file__text'}>
                                                <p className={'upload-file__title upload-file__title_size'}>{getSize(file.f.size)}</p>
                                                <img src={success} alt="success" className={'upload-file__success'}/>
                                                <img src={deleteFile} alt="delete" className={'upload-file__delete'}
                                                     onClick={() => deleteFileFunc(file.id, file.f.size)}/>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            {!uploadFiles.length
                                ? <>
                                    <div className={'upload-file'}>
                                        <p className={'upload-file__title'}>Файл не выбран</p>
                                    </div>
                                    {getFileLines(uploadFiles.length, 3)}
                                </>
                                : <>{getFileLines(uploadFiles.length, 4)}</>
                            }
                        </div>
                        <button className={'ask-form__button'} type={"submit"}>Отправить</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AskForm