import React from 'react'
import './style.scss'
import image from './images/successImage.png'

const RequestSuccess = () => {

    return(
        <div className={'request-success-wrapper'}>
            <div className={'request-success'}>
                <img src={image} alt="success image"/>
                <h1 className={'request-success__title'}>Заявка отправлена</h1>
                <p className={'request-success__text'}>Вы получите ответ на почту в ближайшее время</p>
            </div>
        </div>
    )
}

export default RequestSuccess