import React from 'react'
import Menu from "../../Components/Menu";
import Footer from "../../Components/Footer";

const Success = (props) => {
    return(
        <>
          <Menu/>
          {props.component()}
          <Footer/>
        </>
    )
}

export default Success