import React, {Fragment} from 'react'
import {v4 as uuid} from 'uuid'
import './style.scss'
import {politicText} from './politicText'

const Politic = (props) => {

    const as = ({target}) => {
        if(target.className === 'modal-background' || target.className === 'modal-wrapper') {
            props.politic(false)
        }
    }
    return (
        <div className={'modal-background'} onClick={(event) => as(event)}>
            <div className={'modal-wrapper'}>
                <div className={'politic'}>
                    <div className={'modal-button'} onClick={() => props.politic(false)}>
                        <div className={'modal-button__element modal-button__right'}/>
                        <div className={'modal-button__element modal-button__left'}/>
                    </div>
                    {politicText.map(item => {
                        return (
                            <Fragment key={uuid()}>
                                <h1 className={'politic__title'}>{item.title}</h1>
                                <p className={'politic__text'}>{item.text}</p>
                            </Fragment>
                        )
                    })
                    }

                </div>
            </div>
        </div>
    )
}

export default Politic