import React, {useRef, useState} from 'react'
import SimpleReactValidator from "simple-react-validator";

const EnterPassword = (props) => {

    const correctPassword = '1234'
    const [password, setPassword] = useState('')
    const [notValid, setNotValid] = useState(false)

    const validator = useRef(new SimpleReactValidator({
        element: () => <span className={`validation__not-valid`}>Неверный пароль</span>
    }))

    const handler = (event) => {
        setPassword(event.target.value)
    }

    const verifyPassword = () => {
        if (password === correctPassword) {
            console.log('correct')
            setNotValid(false)
            validator.current.hideMessages()
            document.getElementById('password').classList.remove('form__not-valid')
            props.setCorrect(true)
        } else {
            setNotValid(true)
            console.log('no')
            validator.current.showMessages()
            document.getElementById('password').classList.add('form__not-valid')
        }
    }

    return (
        <div className={'form-distributive__password-wrapper'}>
            <div className={'form-distributive__password'}>
                <label className={'form-distributive__label'}>Пароль</label>
                <input type="password" className={'form-distributive__input'} value={password} id={'password'}
                       placeholder={'Введите пароль'} onChange={(e) => handler(e)}/>
                {validator.current.message('password', password, 'accepted')}
            </div>
            <button className={'form-distributive__button'} onClick={verifyPassword}>Войти</button>
        </div>
    )
}

export default EnterPassword