import React, {useState, useCallback} from 'react'
import './style.scss'
import {useDropzone} from 'react-dropzone'
import {sendForm} from '../../assets/axios/sendForm'
import {sendSuccess} from '../../assets/js/functons'
import success from "./images/success.png";
import form from './files/form.xlsx'

const RequestForExams = () => {

    const [downloadedFiles, setDownloadedFiles] = useState('')

    const [step1, setStep1] = useState(false)
    const [step2, setStep2] = useState(false)

    const titleStep2 = step1 ? 'step__title step__title_active' : 'step__title'
    const blockStep2 = step1 ? 'step__block step__block_active' : 'step__block'
    const textStep2 = step1 ? 'step__text step__text_active' : 'step__text'

    const titleStep3 = step2 ? 'step__title step__title_active' : 'step__title'
    const blockStep3 = step2 ? 'step__block step__block_final_active' : 'step__block step__block_final'

    const onDrop = useCallback(acceptedFiles => {
        const file = acceptedFiles[0]
        const titlesArray = file.name.split('.')
        const extension = titlesArray[titlesArray.length - 1]

        if(extension === 'xlsx') {
            setDownloadedFiles(acceptedFiles)
            checkStatus(2, true)
        }
    }, [])
    const {getRootProps, getInputProps} = useDropzone({onDrop})

    const checkStatus = (step, status) => {
        if (step === 1 && status) {
            setStep1(true)
        }

        if (step === 2 && status) {
            setStep2(true)
        }

        if (step === 3 && status) {
            if (step2) {
                sendForm(downloadedFiles, sendSuccess('/#/success_request'))
            }
        }
    }

    return (
        <div className={'forExams-wrapper'}>
            <div className={'forExams__text-wrapper'}>
                <h1 className={'forExams__title'}>Заявка на проведение нескольких экзаменов</h1>
                <p className={'forExams__text'}>Для подачи заявки на проведение нескольких экзаменов
                    скачайте Excel-файл с формой заявки, заполните ее,
                    загрузите заполненную форму в окно загрузки и отправьте.</p>
            </div>
            <div className={'forExams__steps-wrapper'}>
                <div className={'step'}>
                    <h1 className={'step__title step__title_active'}>1</h1>
                    <a className={'step__block step__block_active'}
                       onClick={() => checkStatus(1, true)}
                       href={form} download={'Форма_подачи_заявок_на_порведение_экзаменов'}>
                        {step1
                            ? <img src={success} alt="success"/>
                            : <p className={'step__text step__text_active'}>Скачать Excel-файл с формой заявки</p>
                        }
                    </a>
                </div>
                <div className={'arrow arrow_active'}/>
                <div {...getRootProps()} className={'step'}>
                    {step1 && !step2
                        ? <input {...getInputProps()} />
                        : ''
                    }
                    <h1 className={titleStep2}>2</h1>
                    <div className={blockStep2}>
                        {downloadedFiles
                            ? <img src={success} alt="success"/>
                            : <p className={textStep2}>Загрузить заполненную форму</p>
                        }
                    </div>
                </div>
                <div className={step1 ? 'arrow arrow_active' : 'arrow'}/>
                <div className={'step'}>
                    <h1 className={titleStep3}>3</h1>
                    <div className={blockStep3} onClick={() => checkStatus(3, true)}>
                        <p className={'step__text step__text_final'}>Отправить заявку</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RequestForExams