import React from 'react'
import Menu from "../../Components/Menu";
import FormRequest from "../../Components/FormRequest";

const RequestExam = () => {
    return (
        <>
            <Menu/>
            <FormRequest/>
        </>
    )
}

export default RequestExam