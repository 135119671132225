import React from 'react'
import Menu from "../../Components/Menu";
import AskForm from "../../Components/AskForm";

const AskQuestion = () => {
    return(
        <>
         <Menu/>
         <AskForm/>
        </>
    )
}

export default AskQuestion