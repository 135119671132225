import React, {useState} from 'react'
import './style.scss'
import Politic from "../Politic";

const Footer = () => {

    const [politicModal, setPoliticModal] = useState(false)

    const body = document.getElementById('body')
    politicModal ? body.classList.add('hidden-body') : body.classList.remove('hidden-body')

    return (
        <div className={'footer'}>
            {politicModal
                ? <Politic politic={setPoliticModal}/>
                : ''
            }
            <p className={'footer__politic'} onClick={() => setPoliticModal(true)}>Политика конфиденциальности</p>
        </div>
    )
}

export default Footer