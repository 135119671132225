import React from 'react'
import distr from './images/distributive.png'

const CorrectPassword = () => {
    return(
        <div className={'form-distributive__instruction'}>
            <img src={distr} alt="teacher"/>
            <div className={'form-distributive__instruction-text-wrapper'}>
                <p className={'form-distributive__instruction__title'}>Дистрибутив</p>
                <p className={'form-distributive__instruction__text'}>Dsfagertcvb.zip <br/>5.2 МБ</p>
            </div>
        </div>
    )
}

export default CorrectPassword